import Archive1 from "../../../assets/archive1.png"
import Archive2 from "../../../assets/archive2.png"
import Archive3 from "../../../assets/archive3.png"
import Archive4 from "../../../assets/archive4.png"
import Archive5 from "../../../assets/archive5.png"
import Archive6 from "../../../assets/archive6.png"

export default function ArchiveImages() {
    return (
        <div className='flex flex-col justify-center items-center gap-5 md:gap-9 mt-[48px] md:mt-[98px] drop-shadow-md rounded-[15px] pt-5 md:pt-0'>
            <img src={Archive1} alt='archive1' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Archive2} alt='archive2' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Archive3} alt='archive3' className='max-w-[390px] md:max-w-[996px] rounded-[4px]'/>
            <img src={Archive4} alt='archive3' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Archive5} alt='archive3' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Archive6} alt='archive3' className='max-w-[390px] md:max-w-[996px]'/>
        </div>
    )

}
