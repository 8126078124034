import { Button } from "../../../ui-library/Button/button";
import { motion } from "framer-motion";
import ProfilePic from "../../../assets/profile-pic.png";
import ArrowIcon from "../../../assets/arrow-icon.svg";
import {useNavigate} from "react-router-dom";


export default function Intro({ onWorkClick }:{ onWorkClick: () => void }) {
    const navigate = useNavigate();

    const headerText = "Product Designer with 3+ years of experience building apps, websites and brands, based in Delhi.";
    const container = {
        hidden: { opacity: 1 },
        visible: {
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.1,
            },
        },
    };

    const wordAnimation = {
        hidden: { opacity: 0, filter: 'blur(10px)', x: -50 },
        visible: { opacity: 1, filter: 'blur(0px)', x: 0, transition: { duration: 0.2, ease: "easeOut" } },
    };

    const goToAbout = () => {
        navigate("/about");
    }

    return (
        <>
        {/* web */}
        <div
            className='hidden md:flex relative justify-between items-center mx-[83px] 2xl:mx-[240px] mt-[200px] border-y border-[#D0D5DD] font-[600] text-[40px] leading-[48px] px-[25px]'>
            <div className='hidden md:flex absolute top-[-24px] left-[24px] w-[1px] h-[100%] bg-[#D0D5DD] opacity-[70%]'/>
            <div className='hidden md:flex absolute top-[-24px] right-[24px] w-[1px] h-[100%] bg-[#D0D5DD] opacity-[70%]'/>
            <div className='hidden md:flex absolute top-[-24px] left-[384px] w-[1px] h-[100%] bg-[#D0D5DD] opacity-[70%]'/>
            <img src={ProfilePic} alt='profile' className='w-[359px]'/>
            <div className='pl-[58px]'>
                <motion.div
                    className='overflow-hidden'
                    variants={container}
                    initial="hidden"
                    animate="visible"
                >
                    {headerText.split(" ").map((word, index) => (
                        <motion.span
                            key={index}
                            className='inline-block mr-2 tracking-[-0.2px]'
                            variants={wordAnimation}
                        >
                            {word}
                        </motion.span>
                    ))}
                </motion.div>
                <div className='flex gap-5 mt-[51px]'>
                    <Button
                        className='relative rounded-[70px] border border-[#CFD5DE] font-[500] text-[14px] text-[#292930] text-center p-3 leading-4 w-[96px] hover:border-black overflow-hidden'
                        onClick={onWorkClick}
                    >
                        Work
                    </Button>


                    <Button
                        onClick={goToAbout}
                        className='relative rounded-[70px] border border-[#CFD5DE] font-[500] text-[14px] text-[#292930] text-center p-3 leading-4 w-[96px] hover:border-black overflow-hidden'>
                        About
                    </Button>

                    <a href="mailto:adityapratap230@gmail.com">
                        <Button
                            className='flex rounded-[48px] bg-black border border-[#CFD5DE] font-[500] text-[14px] text-white text-center p-3 leading-4 w-[150px] hover:border-black overflow-hidden'>
                            <p>Contact</p>
                            <img src={ArrowIcon} alt='arror' className='w-3 h-3'/>
                        </Button>
                    </a>
                </div>
            </div>

            <div className='hidden md:flex absolute bottom-[-24px] left-[24px] w-[1px] h-[30%] bg-[#D0D5DD] opacity-[70%]'/>
            <div className='hidden md:flex absolute bottom-[-24px] right-[24px] w-[1px] h-[30%] bg-[#D0D5DD] opacity-[70%]'/>
            <div className='hidden md:flex absolute bottom-[-24px] left-[384px] w-[1px] h-[30%] bg-[#D0D5DD] opacity-[70%]'/>
        </div>

            {/* Mobile */}
            <>
                <div
                    className='flex md:hidden flex-col relative  mt-[50px] border-y border-[#D0D5DD] font-[600] text-[24px]'>
                    <div className='absolute top-[-24px] left-[24px] w-[1px] h-[100%] bg-[#D0D5DD] opacity-[70%]'/>
                    <img src={ProfilePic} alt='profile' className='w-[245px] mx-[25px] border-b'/>
                </div>
                <div className='flex flex-col md:hidden mx-[25px] border-l border-[#D0D5DD] p-3'>
                    <motion.div
                        className='overflow-hidden'
                        variants={container}
                        initial="hidden"
                        animate="visible"
                    >
                        {headerText.split(" ").map((word, index) => (
                            <motion.span
                                key={index}
                                className='inline-block mr-1 font-[600] tracking-[-0.2px] text-[24px] leading-[32px]'
                                variants={wordAnimation}
                            >
                                {word}
                            </motion.span>
                        ))}
                    </motion.div>
                    <div className='flex gap-5 mt-[32px]'>
                        <Button
                            className='relative rounded-[70px] border border-[#CFD5DE] font-[500] text-[14px] text-[#292930] text-center p-3 leading-4 w-[96px] hover:border-black overflow-hidden'
                            onClick={onWorkClick}
                        >
                            Work
                        </Button>


                        <Button
                            onClick={goToAbout}
                            className='relative rounded-[70px] border border-[#CFD5DE] font-[500] text-[14px] text-[#292930] text-center p-3 leading-4 w-[96px] hover:border-black overflow-hidden'>
                            About
                        </Button>

                    </div>
                </div>

            </>
        </>
    );
}
