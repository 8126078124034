export default function HeroSection() {
    return (
        <div className='flex flex-col justify-center gap-6 max-w-[636px] pb-[56px] border-b border-[#D0D5DD]'>
            <p className='font-[600] text-[24px] md:text-[40px] text-[#292930] leading-[32px] md:leading-[48px] tracking-[-0.2px]'>
                Hi there, my name is Aditya Pratap, and I'm here to help you build better digital products.
            </p>
            <p className='font-[400] text-[#71717B]'>
                I specialize in crafting visually striking and intuitively user-friendly interfaces.
                Skilled in Figma, I adeptly navigate all design stages, from user research to polished visual execution.
                I am passionate about collaborating with startups and established brands, transforming complex concepts
                into streamlined, functional designs.
            </p>
            <a
                href="./resume_aditya.pdf"
                download="Aditya_Pratap_Resume.pdf"
                className='flex rounded-[48px] bg-white border border-[#CFD5DE] font-[500] text-[14px] text-[#171726]
                text-center p-3 leading-4 w-[150px] hover:border-black overflow-hidden'
            >
                <p>Download Resume</p>
            </a>
        </div>
    );
}
