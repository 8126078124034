import {Button} from "../ui-library/Button/button";
import ArrowIcon from "../assets/arrow-black.svg";
import {useNavigate} from "react-router-dom";

export default function Footer({ onWorkClick, className }:{ onWorkClick: () => void, className?: string }) {
    const navigate = useNavigate();

    const goToAbout = () => {
        navigate("/about");
    }
    return (
        <div className={`relative bg-black px-5 pt-5 md:px-[78px] md:pt-[102px] mt-[140px] overflow-hidden pb-[200px] md:pb-[350px] ${className}`}>
            <div className='flex flex-col md:flex-row justify-between items-start w-full'>
                <div>
                    <div className='flex flex-col md:hidden font-[400] text-[24px] md:text-[32px] text-[#6F6F78]'>
                        <p>Please feel free to contact me for information, new projects or collaborations.</p>
                        <a href="mailto:adityapratap230@gmail.com">
                            <Button
                                className='mt-5 md:mt-7 flex rounded-[48px] bg-white border border-[#CFD5DE] font-[500] text-[14px] text-[#171726] text-center md:p-3 leading-4 md:w-[150px] hover:border-black overflow-hidden'>
                                <p>Contacts</p>
                                <img src={ArrowIcon} alt='arror' className='w-3 h-3'/>
                            </Button>
                        </a>
                    </div>
                    <div className='mt-[80px] md:mt-0 grid md:grid-cols-2 gap-4 md:gap-x-[173px] md:gap-y-[32px] font-[500] text-white text-[32px] md:text-[40px]'>
                        <p className='hover:text-[#FF6432] cursor-pointer' onClick={onWorkClick}>Work</p>
                        <a href='https://www.linkedin.com/in/adityapratap230/' target="_blank"
                           className='hover:text-[#FF6432] cursor-pointer' rel="noreferrer">LinkedIn</a>
                        <p className='hover:text-[#FF6432] cursor-pointer' onClick={goToAbout}>About</p>
                        <a href='https://www.behance.net/adityapratap8/' target="_blank"
                           className='hover:text-[#FF6432] cursor-pointer' rel="noreferrer">Behance</a>
                    </div>
                    <div className='flex flex-col gap-3 font-[400] text-[#444450] mt-[100px] md:mt-[55px]'>
                        <p>Delhi, India</p>
                        <a href="mailto:adityapratap230@gmail.com"
                           className='text-[#FF6432]'>adityapratap230@gmail.com</a>
                        <p>+918797616890</p>

                    </div>
                </div>
                <div className='hidden md:flex md:flex-col font-[400] text-[32px] text-[#6F6F78] w-[50%] pl-20'>
                    <p>Please feel free to contact me for information, new projects or collaborations.</p>
                    <a href="mailto:adityapratap230@gmail.com">
                        <Button
                            className='mt-7 flex rounded-[48px] bg-white border border-[#CFD5DE] font-[500] text-[14px] text-[#171726] text-center p-3 leading-4 w-[150px] hover:border-black overflow-hidden'>
                            <p>Contacts</p>
                            <img src={ArrowIcon} alt='arror' className='w-3 h-3'/>
                        </Button>
                    </a>
                </div>
            </div>
            <p className='hidden md:flex absolute bottom-[-80px] left-[14px] md:left-0 font-[600] text-white text-[83px] md:text-[229px] opacity-[0.08]'>
                Aditya Pratap</p>
            <p className='flex: md:hidden absolute bottom-0 left-[14px] md:left-0 font-[600] text-white text-[70px] md:text-[229px] opacity-[0.08]'>
                Aditya Pratap</p>
        </div>
    );
}
