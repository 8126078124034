export default function ServiceSection({slNo, role, description, noPadding, isFirst}:{slNo: string, role: string,
    description:string, noPadding?:boolean, isFirst?:boolean}) {
    return (
        <div>
            {isFirst &&
                <div className='my-10 font-[400] text-[#71717B] text-[12px]'>
                    <p>SERVICES</p>
                </div>
            }
            <div className={`grid grid-cols-2 max-w-[636px] pb-[24px] font-[400] ${!isFirst ? 'mt-8':''} ${!noPadding ? 'border-b border-[#D0D5DD]' : ''}`}>
                <div className='flex gap-[50px]'>
                    <p className='text-[#E4E4E7]'>{slNo}</p>
                    <p className='font-[500] text-[#09090B]'>{role}</p>
                </div>
                <div className='flex'>
                    <p className='text-[#71717B] opacity-[0.8]'>{description}</p>
                </div>
            </div>
        </div>
    );
}
