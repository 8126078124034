import Propacity1 from "../../../assets/propacity1.png"
import Propacity2_1 from "../../../assets/propacity2-1.png"
import Propacity2_2 from "../../../assets/propacity2-2.png"
import Propacity3 from "../../../assets/propacity3.png"
import Propacity4 from "../../../assets/propacity4.png"
import Propacity5 from "../../../assets/propacity5.png"

export default function PropacityImages() {
    return (
        <div className='flex flex-col justify-center items-center gap-5 md:gap-9 mt-[48px] md:mt-[98px]'>
            <img src={Propacity1} alt='propacity1' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Propacity4} alt='propacity4' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Propacity5} alt='propacity4' className='max-w-[390px] md:max-w-[996px]'/>
            <div className='flex justify-between items-center gap-4 md:gap-8'>
                <img src={Propacity2_1} alt='propacity2_1' className='max-w-[187px] md:max-w-[482px]'/>
                <img src={Propacity2_2} alt='propacity2_2' className='max-w-[187px] md:max-w-[482px]'/>
            </div>
            <img src={Propacity3} alt='propacity3' className='max-w-[390px] md:max-w-[996px]'/>
        </div>
    )

}
