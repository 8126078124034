import {useEffect, useRef} from "react";
import Intro from "./components/intoSection";
import Divider from "./components/divider";
import ExperienceContent from "./components/ExperienceContent";
import AttainImages from "./components/attainImages";
import PropacityImages from "./components/propacityImages";
import HeroContent from "./components/heroContent";
import ArchiveImages from "./components/archiveImages";
import {useLocation} from "react-router-dom";
import Footer from "../../components/footer";
import Heading from "../../components/heading";

export default function Home() {
    const workSectionRef = useRef<null | HTMLDivElement>(null);
    const location = useLocation();

    const scrollToWorkSection = () => {
        if (workSectionRef.current) {
            workSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const section = queryParams.get('section');
        if(section === 'work') {
            scrollToWorkSection();
        }
    }, []);

    return (
        <div className='relative'>
            <Heading/>
            <Intro onWorkClick={scrollToWorkSection}/>
            <HeroContent/>
            <div ref={workSectionRef} id="work-section" className='flex justify-center items-center'>
                <Divider text="Selected work from 2020 to present"/>
            </div>
            <ExperienceContent slNo='01'
                               heading='Propacity '
                               description="Propacity, is a prop-tech company aiming to empower stakeholders
                               in India's real estate industry, enhancing their efficiency, reliability, and
                               solving for velocity in real estate transactions."
                               footerText1="Brand idenity, Logo design"
                               footerText2="Website"
                               hoverLink='https://propacity.com/'/>
            <PropacityImages/>
            <ExperienceContent slNo='02'
                               heading='Attain'
                               description="Propacity, is a prop-tech company aiming to empower stakeholders
                               in India's real estate industry, enhancing their efficiency, reliability, and
                               solving for velocity in real estate transactions."
                               footerText1="Interaction design"
                               footerText2="Coming Soon"/>
            <AttainImages/>

            <ExperienceContent slNo='03'
                               heading='Archives'
                               description="Some examples of my work can be found on Behance and Dribbble, where I showcase a variety of projects that highlight my skills"
                               footerText1="Web Design, Mobile App Design"
                               footerText2="Open Link"
                               link='https://www.behance.net/adityapratap8'/>
            <ArchiveImages/>
            <Footer onWorkClick={scrollToWorkSection}/>
        </div>
    );
}
