import * as React from 'react';
import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '../lib/utils';

const buttonVariants = cva('flex gap-2 items-center justify-center', {
    variants: {
        variant: {
            default: 'bg-primary text-primary-foreground rounded-button hover:bg-primary-hover px-[0.625rem] py-[1rem]',
            primary: 'bg-primary text-primary-foreground rounded-button hover:bg-primary-hover px-[0.625rem] py-[1rem]',
            destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
            outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
            secondary:
                'bg-button-secondary hover:bg-button-secondary-hover ' +
                'border-[0.063rem] border-button-secondary-border hover: text-button-secondary-hoverForeground',
            tertiary: 'hover:bg-accent hover:text-accent-foreground',
            link: 'text-[#262626] h-[1.25rem] font-semibold',
            nude: 'bg-transparent hover:bg-[#F9FAFB]',
            ghost: 'bg-transparent text-[#292930] hover:bg-[#D0D5DD] hover:text-[#101828]',
        },
        size: {
            default: 'py-[0.625rem] px-[1rem] w-[max-content] rounded-button',
            sm: 'px-[0.875rem] py-[0.5rem] w-[max-content] rounded-button font-[600] text-[0.875rem] shadow-sm',
            md: 'px-[1rem] py-[0.625rem] w-[max-content] rounded-[10px] shadow-md',
            lg: 'px-[1.125rem] py-[0.625rem] w-[max-content] rounded-button shadow',
            xl: 'p-[0.875rem] w-[max-content] rounded-button shadow-lg',
            xxl: 'p-[1rem] w-[max-content] rounded-button shadow-xxl',
        },
        iconSize: {
            default: 'p-[0.625rem]',
            sm: 'p-[0.5rem]',
            md: 'p-[0.625rem]',
            lg: 'p-[0.75rem]',
            xl: 'p-[0.875rem]',
            xxl: 'p-[1rem]',
        },
    },
    defaultVariants: {
        variant: 'default',
        size: 'default',
    },
});

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    icon?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({asChild = false, className, variant, size = 'default', icon, ...props}, ref) => {
        const Comp = asChild ? Slot : 'button';
        if (icon) {
            return <Comp className={cn(buttonVariants({variant, iconSize: size, className}))} ref={ref} {...props} />;
        }

        return <Comp className={cn(buttonVariants({variant, size, className}))} ref={ref} {...props} />;
    },
);
Button.displayName = 'Button';

export {Button, buttonVariants};
