import Attain1 from "../../../assets/attain1.png"
import Attain2 from "../../../assets/attain2.png"
import Attain3 from "../../../assets/attain3.png"

export default function AttainImages() {
    return (
        <div className='flex flex-col justify-center items-center gap-5 md:gap-9 mt-[48px] md:mt-[98px]'>
            <img src={Attain1} alt='attain1' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Attain2} alt='attain2' className='max-w-[390px] md:max-w-[996px]'/>
            <img src={Attain3} alt='attain3' className='max-w-[390px] md:max-w-[996px]'/>
        </div>
    )

}
