import Footer from "../../components/footer";
import {useNavigate} from "react-router-dom";
import Header from "./components/header";
import HeroSection from "./components/heroSection";
import ServiceSection from "./components/serviceSection";
import ExperienceContent from "./components/ServiceContent";

export default function About() {
    const navigate = useNavigate();

    const onWorkClick = () => {
        navigate("/home/?section=work");
    }

    return(
        <div>
            <Header/>
            <div className='flex flex-col justify-center items-center mx-5 mt-[80px] md:mt-0'>
                <HeroSection/>
                <div className='hidden md:flex md:flex-col'>
                <ServiceSection isFirst
                                slNo='01'
                                role='Product design'
                                description='User-centric digital product creation, from concept to prototype, focusing on intuitive experiences and problem-solving.'/>
                <ServiceSection slNo='02'
                                role='Website design'
                                description='Responsive, visually compelling websites optimized for user engagement and conversion rates.'/>
                <ServiceSection noPadding
                                slNo='03'
                                role='Branding'
                                description='Comprehensive brand identity development, including logos, color schemes, and guidelines for consistent representation.'/>
                </div>
                <div className='flex flex-col md:hidden'>
                    <p className='pt-10 font-[400] text-[12px] text-[#71717B]'>SERVICES</p>
                    <ExperienceContent slNo='01'
                                       heading='Product design'
                                       description="User-centric digital product creation, from concept to prototype, focusing on intuitive experiences and problem-solving."                     />

                    <ExperienceContent slNo='02'
                                       heading='Website design'
                                       description="Responsive, visually compelling websites optimized for user engagement and conversion rates."/>

                    <ExperienceContent slNo='03'
                                       heading='Branding'
                                       description="Responsive, visually compelling websites optimized for user engagement and conversion rates."
                                       />

                </div>
            </div>
            <Footer onWorkClick={onWorkClick} className='mt-[68px] md:mt-16'/>
        </div>
    )
}
