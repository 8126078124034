"use client";

import React from "react";
import Carousel1 from "../../../assets/carousel1.png";
import Carousel2 from "../../../assets/carousel2.png";
import Carousel3 from "../../../assets/carousel3.png";
import Carousel4 from "../../../assets/carousel4.png";
import Carousel5 from "../../../assets/carousel5.png";
import Carousel6 from "../../../assets/carousel6.png";
import Carousel7 from "../../../assets/carousel7.png";
import "./heroContent.css";

const images = [
    { url: Carousel1, title: "carousel-1" },
    { url: Carousel2, title: "carousel-2" },
    { url: Carousel3, title: "carousel-3" },
    { url: Carousel4, title: "carousel-4" },
    { url: Carousel5, title: "carousel-5" },
    { url: Carousel6, title: "carousel-6" },
    { url: Carousel7, title: "carousel-7" },
];

export default function HeroContent() {
    const logos = images.map((image, index) => (
        <img
            className={`max-w-[390px] max-h-[288px] md:max-w-[1205px] md:max-h-[704px] h-auto mx-2 md:mx-4 shadow-custom rounded-[8px] md:rounded-[16px] my-5`}
            key={index}
            src={image.url}
            alt={image.title}
        />
    ));


    return (
        <div className="w-full">
            <div className={"flex py-4 md:py-20 flex-col mt-4 md:mt-0"}>
                <div className="inline-flex flex-nowrap mt-4 md:mt-12 w-full overflow-hidden">
                    <div className="flex animate-infinite-scroll">{logos}</div>
                    <div className="flex animate-infinite-scroll" aria-hidden="true">
                        {logos}
                    </div>
                </div>
            </div>
        </div>
    );
}
