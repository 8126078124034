import {Outlet} from "react-router-dom";
import {ReactNode} from "react";

export default function Layout({ children }: {children?: ReactNode;}) {
    return (
        <div className="relative">
            <Outlet />
            {children}
        </div>
    );
}
