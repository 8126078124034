import Ellipses from "../../../assets/ellipse.svg";
import {MoveUpRight} from "lucide-react";
import ArrowGrey from "../../../assets/arrow-grey.svg";
import {LinkPreview} from "../../../ui-library/LinkPreview";
export default function ExperienceContent({ slNo, heading, description, footerText1, footerText2, link, hoverLink }:
{ slNo: string, heading: string, description: string, footerText1?: string, footerText2?: string, link?: string, hoverLink?:string }) {
    return (
        <div className="flex flex-col justify-center items-center mt-[68px] md:mt-[160px] mx-5 md:mx-0">
            <div className="flex flex-col max-w-[640px] my-4 text-[#71717B] border-b border-[#CFD5DE] pb-6">
                <div className="flex gap-6 font-[500] text-[#09090B] text-[28px] md:text-[40px] mb-4">
                    <p className="text-[#E4E4E7]">{slNo}</p>
                    <p>{heading}</p>
                </div>
                <p>
                    {description}
                </p>
            </div>

            <div className="flex justify-between w-full max-w-[640px] font-[400] text-[#71717B]">
                {footerText1 && <p>{footerText1}</p>}
                {!link && !hoverLink && footerText2 && (
                    <div className='flex justify-start items-center gap-3 pr-2'>
                        <p>{footerText2}</p>
                        <img src={Ellipses} alt='ellipses'/>
                    </div>
                )}
                {link && footerText2 && (
                    <a href={link} target="_blank" rel="noopener noreferrer" className='flex justify-center items-center pr-2 cursor-pointer hover:text-[#344054]'>
                        <p>{footerText2}</p>
                        <img src={ArrowGrey}  alt='arrow'/>
                    </a>
                )}
                {hoverLink && footerText2 && (
                    <LinkPreview url={hoverLink}
                                 className="flex justify-center items-center pr-2 cursor-pointer text-[#71717B] hover:text-[#344054]">
                        <p className='text-[#71717B]'>{footerText2}</p>
                        <img src={ArrowGrey} alt='arrow'/>
                    </LinkPreview>
                )}
            </div>
        </div>
    );
}
