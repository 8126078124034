import {Button} from "../ui-library/Button/button";
import ArrowIcon from "../assets/arrow-icon.svg";

export default function Heading() {
    return(
        <div>
            <p className='hidden md:flex absolute top-[-15.313rem] left-[4.688rem] font-[600] text-[#292930] text-[218px] opacity-[0.08]'>Aditya Pratap</p>
            <div className='flex md:hidden justify-between items-center font-[600] text-[#292930] mx-5 mt-7'>
                <p>Aditya Pratap</p>
                <a href="mailto:adityapratap230@gmail.com">
                    <Button
                        className='flex rounded-[48px] bg-black border border-[#CFD5DE] font-[500] text-[14px] text-white text-center p-3 leading-4 w-[118px] hover:border-black overflow-hidden'>
                        <p>Contacts</p>
                        <img src={ArrowIcon} alt='arror' className='w-3 h-3'/>
                    </Button>
                </a>
            </div>
        </div>
    )
}
