import {MoveLeft} from "lucide-react";
import {Button} from "../../../ui-library/Button/button";
import ArrowIcon from "../../../assets/arrow-icon.svg";
import {useNavigate} from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();

    const goHome = () => {
        navigate("/home");
    }
    return (
        <>
            <div className='flex justify-between mx-5 mt-7 md:mt-[30px] md:mx-[120px]'>
                <div className='flex justify-center items-center gap-2 cursor-pointer ' onClick={goHome}>
                    <MoveLeft/>
                    <p>Back</p>
                </div>
                <a href="mailto:adityapratap230@gmail.com">
                    <Button
                        className='flex rounded-[48px] bg-black border border-[#CFD5DE] font-[500] text-[14px] text-white text-center px-3 leading-4 w-[150px] hover:border-black overflow-hidden'>
                        <p>Contact</p>
                        <img src={ArrowIcon} alt='arror' className='w-3 h-3'/>
                    </Button>
                </a>
            </div>
            <p className='hidden md:flex font-[600] text-[#292930] text-[218px] opacity-[0.08] ml-[70px] leading-[240px]'>Aditya
                Pratap
            </p>
        </
    >
)
}
